@mixin credit {
  font-size: 10px;
  letter-spacing: 0.03em;
  line-height: 1.8em;
}

@mixin h1 {
  font-size: 24px;
  line-height: normal;
  letter-spacing: 0.02em;
}

@mixin h2 {
  font-size: 22px;
  font-style: normal;
  line-height: 111.111%;
  letter-spacing: 0.04em;
}

@mixin h3 {
  font-size: 20px;
  font-style: normal;
  line-height: 111.111%;
  letter-spacing: 0.04em;
}

@mixin h4 {
  font-size: 18px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.02em;
}

@mixin text-base {
  font-size: 16px;
}

@mixin text-sm {
  font-size: 14px;
}

@mixin text-xsm {
  font-size: 12px;
}

@function sm-vw-converter($px, $add: 0) {
  $value: math.div($px, 375) * 100 * 1vw;
  @if ($add != 0) {
    $value: calc(#{$value} + #{$add});
  }
  @return $value;
}

@function unit-calculation($x, $y, $unit: 'em') {
  $value: $x / $y;

  @if ($unit == 'em') {
    $value: round($value * 100) / 100;
  } 
  @else if ($unit == '%') {
    $value: $value * 100;
  } 
  @else {
    $value: round($value * 100) / 100;
  }
  
  @return #{$value}#{$unit};
}
