@charset "utf-8";
@import "_settings.scss";
@import "_responsive.scss";
@import "_mixin.scss";
@import "_keyframes.scss";
@import "common.scss";


.indicator {
  // opacity: 0;
  position: absolute;
  left: $containerPD;
  bottom: 10px;
  margin-top: 20px;
  height: 120px;
  font-family: $ff;
  font-size: 1.2rem;
  letter-spacing: 0.1em;
  // transition: $transition01;
  @include respond-to(md) {
    left: $mdContainerPD;
    bottom: 32px;
  }
  &.executed {
    opacity: 1;
  }
  p {
    display: inline-block;
    position: relative;
    left: 24px;
    font-size: 1rem;
    transform: rotate(90deg);
    transform-origin: left top;
  }
  .bar-track {
    display: inline-block;
    overflow: hidden;
    position: absolute;
    left: 2px;
    top: 0;             
    width: 1px;
    height: 120px;
  }
  .bar {
    display: inline-block;
    position: absolute;
    left: 0;
    width: 1px;
    height: 120px;
    background-color: $primaryColor;
    animation: indicator 1s ease 0.5s infinite;
  }
}


.hero-section {
  position: relative;
  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 100vh;
  }
  .indicator,
  .section__content {
    opacity: 0;
    transition: opacity ease 0.8s;
    &.visible {
      opacity: 1;
    }
  }
  .indicator {
    transition: opacity ease 0.8s;
    &.indicator--hidden {
      opacity: 0;
    }
  }
  .slogan {
    margin-bottom: 24px;
    color: $primaryColor;
    font-size: 3.2rem;
    font-weight: 500;
    line-height: 1.4em;
    @include respond-to(md) {
      font-size: 5.6rem;
    }
  }
  .headline {
    font-size: 1.8rem;
  }
}

.about-section {
  color: #fff;
  background-color: $primaryColor;
  .row {
    margin-right: -16px;
    margin-left: -16px;
    .col {
      padding: 16px;
    }
  }
  .service {
    &__title {
      margin-bottom: 8px;
      font-size: 2rem;
      font-weight: 500;
    }
  }
  .section__content {
    padding-right: 0;
    @include respond-to(md) {
      padding-right: 16px;
      max-width: 75%;
    }
  }
}

.company-section,
.contact-section {
  .container {
    padding-bottom: 80px;
    @include respond-to(md) {
      padding-bottom: 120px;
    }
  }
  dl {
    line-height: 1.8em;
    @include respond-to(md) {
      display: flex;
      flex-wrap: wrap;
      width: 75%;
    }
    dt {
      font-size: 1.3rem;
      font-weight: 500;
      @include respond-to(md) {
        margin-bottom: 32px;
        width: 33.33%;
        font-size: 1.6rem;
        font-weight: 400;
      }
    }
    dd {
      margin-bottom: 16px;
      @include respond-to(md) {
        margin-bottom: 0;
        padding-left: 16px;
        width: 66.66%;
      }
    }
  }
}

.no-apple {
  .privacy-policy {
    &::-webkit-scrollbar{
      width: 2px;
      -webkit-appearance: none;
    }
    &::-webkit-scrollbar-track{
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb{
      background-color: #777;
    }
  }
}

.contact-section {
  background-color: #f4f4f4;
  .required {
    color: $red;
    font-size: 2rem;
  }
  dl {
    dt {
      margin-bottom: 8px;
      @include respond-to(md) {
        margin-bottom: 0;
      }
    }
    dd {
      margin-bottom: 32px;
    }
  }
  .form-action {
    @include respond-to(md) {
      width: 75%;
    }
    &__inner {
      @include respond-to(md) {
        margin-left: 33.33%;
        padding-left: 16px;
      }
    }
    button {
      padding: 16px 16px;
      width: 100%;
      color: #fff;
      font-size: 1.6rem;
      font-weight: 500;
      background-color: $baseColor;
      border-radius: 8px;
      border: 1px solid $baseColor;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: #222;
      }
    }
  }
  .error {
    font-size: 1.4rem;
  }
  .privacy-policy {
    overflow: auto;
    margin-bottom: 32px;
    padding: 20px;
    max-height: 200px;
    border-radius: 8px;
    border: 1px solid $lightGray;
    background-color: #fff;
    h1 {
      margin-bottom: 8px;
      font-weight: 600;
    }
    h2 {
      margin-top: 16px;
      margin-bottom: 8px;
      font-weight: 600;
      font-size: 1.5rem;
    }
    h3 {
      margin-top: 24px;
      margin-bottom: 8px;
      font-weight: 600;
    }
    p {
      font-size: 1.4rem;
    }
    ul {
      margin-left: 1.5em;
      font-size: 1.4rem;
      list-style: disc;
      line-height: 1.8em;
    }
  }
  .agreement {
    margin-bottom: 32px;
    input {
      margin-right: 8px;
    }
  }
  #contact-success {
    display: none;
    max-width: 50%;
    &.active {
      display: block;
    }
  }
}