$ff: "Noto Sans JP", "yu-gothic-pr6n", "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "Hiragino Kaku Gothic ProN";
$ffEN: 'SuisseIntl', "yu-gothic-pr6n", "Yu Gothic Medium";
$baseColor: #000;
$primaryColor: #C8000B;
$red: #C8000B;
$blue: #004BFF;
$limeGreen: #47BC00;
$accentColor: #CBFF37;
$darkGray: #474D52;
$gray: #9AA4AD;
$lightGray: #E8E8E8;
$superLightGray: #EEF1F6;
$transition01: all 500ms cubic-bezier(.215, .61, .355, 1);			
$containerPD: 4.2vw;
$mdContainerPD: 40px;
