@import "_settings.scss";
@import "_responsive.scss";
@import "_mixin.scss";
@import "_keyframes.scss";


/* 
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com 
Twitter: @rich_clark
*/

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
  display: block;
}

nav ul {
  list-style :none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input, select {
  vertical-align :middle;
}

picture {
  display: inline-block;
}


/* change colours to suit your needs */
ins {
  background-color:#ff9;
  color:#000;
  text-decoration:none;
}

/* change colours to suit your needs */
mark {
  background-color:#ff9;
  color:#000; 
  font-style:italic;
  font-weight:bold;
}

del {
  text-decoration: line-through;
}

::-webkit-input-placeholder,
::placeholder,
:-ms-input-placeholder,
::-ms-input-placeholder {
  color: $primaryColor;
  font-family: $ff;
  @include respond-to(md) {
    font-size: 12px;
  }
}

abbr[title], dfn[title] {
  border-bottom:1px dotted;
  cursor:help;
}

table {
  border-collapse:collapse;
  border-spacing:0;
}

/* change border colour to suit your needs */
hr {
  display:block;
  height:1px;
  border:0;   
  border-top:1px solid #cccccc;
  margin:1em 0;
  padding:0;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

input, select {
  vertical-align:middle;  
}

textarea,
select,
input[type=text],
input[type=search],
input[type=email],
input[type=tel],
input[type=number],
input[type=password],
input[type=date],
input[type=time] {
  @include text-base();
  display: block;
  padding: 0 20px 0 20px;
  width: 100%;
  // height: 48px;
  max-width: 480px;
  color: $darkGray;
  font-family: $ff;
  letter-spacing: 0.04em;
  line-height: 48px;
  border: 1px solid $lightGray;  
  border-radius: 8px;
  background-color: #fff;
  -webkit-appearance: none;  
  @include respond-to(md) {
    max-width: none;
    font-size: 16px;
  }
  &.has-error {
    color: #f7f7f7;
    background-color: $primaryColor;
  }
  &:focus {
    outline: none;
  }
}

input[type=time] {
  display: inline-block;
  padding: 0 8px 0 8px;
  line-height: 40px;
  width: auto;
}

[hidden] {
  display: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

input[type="radio"]{
  display: none;
  + .checkbox-backdrop {
    display: inline-block;
    position: relative;
    top: 2px;
    margin-right: 3px;
    width: 18px;
    height: 18px;
    border: 2px solid $gray;
    border-radius: 100px;
  }
  &:checked {
    + .checkbox-backdrop {
      border: 5px solid $primaryColor;
    }
  }
}

input[type="checkbox"]{
  position: relative;
  top: -2px;
  width: 24px;
  height: 24px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 3px;
  border: 2px solid $gray;
  &:checked {
    background: $primaryColor url(../img/check.svg) no-repeat center center;
    border-color: $primaryColor;
  }
}

textarea {
  padding: 12px 20px;
  height: 160px;
  max-width: none;
  -webkit-appearance: none;
  line-height: 1.5em;
  border-radius: 8px;
  border: 1px solid $lightGray;  
  &:focus {
    outline: none;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  transition: background-color 5000s;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select {
  @include text-base();
  // padding: 0 20px;
  // padding: 12px 20px 20px 20px;
  width: 100%;
  border: none;
  border: 1px solid $lightGray;  
  border-radius: 0;
  -webkit-appearance: none;
  background: var(--icon-arrow-down-xs) no-repeat right 10px center;
  &:focus {
    outline: none;
  }
}




/**
*
*	common properties
*
**/

html {
  color: $baseColor;
	font-size: 62.5%;
  // background-color: $superLightGray;
}

body {
  color: $baseColor;
	font-family: $ff;
	font-size: 16px;
  font-weight: 400;
  font-feature-settings: "palt";
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border: none;
  line-height:1;
  min-height: 100vh; 
}

img, a {
  border: none;
  outline: none;
}

label {
  cursor: pointer;
}

h1,h2,h3,h4,h5,h6 {
  letter-spacing: 0.03em;
  line-height: 1.4em;
  font-weight: 400;
}

p {
  line-height: 1.8em;
  letter-spacing: 0.02em;
}

main {
  display: block;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

ol, ul {
  list-style: none;
}

a {
  &:link, &:visited {
    color: $baseColor;
    text-decoration: none;
    transition: opacity 500ms cubic-bezier(.215, .61, .355, 1);
  }
  &.hover--zoom {
    display: block;
    overflow: hidden;
    img {
      transition: $transition01;  
      transition-duration: 1000ms;  
    }
    &:hover, &:active {
      opacity: 1;
      img {
        transform: scale(1.04);        
      }
    }
  }
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

svg {
  overflow: visible;
  overflow-y: visible;
  overflow-x: visible;
}

button {
  padding: 0;
  color: $baseColor;
  font-family: $ff;
  font-weight: 400;
  border: none;
  background-color: transparent;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

.clear {
  clear: both;
}

ins {
  display: inline-block;
  vertical-align: bottom;
  background-color: transparent;
  color: inherit;
  text-decoration: none;
}

.no-scroll {
	overflow-y: hidden;
}


.mt10 {margin-top: 10px;}
.mt20 {margin-top: 20px;}
.mt30 {margin-top: 30px;}
.mt40 {margin-top: 40px;}
.mt50 {margin-top: 50px;}
.mt60 {margin-top: 60px;}
.mt70 {margin-top: 70px;}
.mt80 {margin-top: 80px;}
.mt90 {margin-top: 90px;}
.mt100 {margin-top: 100px;}
.mt110 {margin-top: 110px;}
.mt120 {margin-top: 120px;}

.mb10 {margin-bottom: 10px;}
.mb20 {margin-bottom: 20px;}
.mb30 {margin-bottom: 30px;}
.mb40 {margin-bottom: 40px;}
.mb50 {margin-bottom: 50px;}
.mb60 {margin-bottom: 60px;}
.mb70 {margin-bottom: 70px;}
.mb80 {margin-bottom: 80px;}
.mb90 {margin-bottom: 90px;}
.mb100 {margin-bottom: 100px;}
.mb110 {margin-bottom: 110px;}
.mb120 {margin-bottom: 120px;}


.md\:mt10 {
  @include respond-to(md) {
    margin-top: 10px;
  }
}
.md\:mt20 {
  @include respond-to(md) {
    margin-top: 20px;
  }
}
.md\:mt30 {
  @include respond-to(md) {
    margin-top: 30px;
  }
}
.md\:mt40 {
  @include respond-to(md) {
    margin-top: 40px;
  }
}
.md\:mt50 {
  @include respond-to(md) {
    margin-top: 50px;
  }
}
.md\:mt60 {
  @include respond-to(md) {
    margin-top: 60px;
  }
}
.md\:mt70 {
  @include respond-to(md) {
    margin-top: 70px;
  }
}
.md\:mt80 {
  @include respond-to(md) {
    margin-top: 80px;
  }
}
.md\:mt90 {
  @include respond-to(md) {
    margin-top: 90px;
  }
}
.md\:mt100 {
  @include respond-to(md) {
    margin-top: 100px;
  }
}
.md\:mt110 {
  @include respond-to(md) {
    margin-top: 110px;
  }
}
.md\:mt120 {
  @include respond-to(md) {
    margin-top: 120px;
  }
}

.md\:mb10 {
  @include respond-to(md) {
    margin-bottom: 10px;
  }
}
.md\:mb20 {
  @include respond-to(md) {
    margin-bottom: 20px;
  }
}
.md\:mb30 {
  @include respond-to(md) {
    margin-bottom: 30px;
  }
}
.md\:mb40 {
  @include respond-to(md) {
    margin-bottom: 40px;
  }
}
.md\:mb50 {
  @include respond-to(md) {
    margin-bottom: 50px;
  }
}
.md\:mb60 {
  @include respond-to(md) {
    margin-bottom: 60px;
  }
}
.md\:mb70 {
  @include respond-to(md) {
    margin-bottom: 70px;
  }
}
.md\:mb80 {
  @include respond-to(md) {
    margin-bottom: 80px;
  }
}
.md\:mb90 {
  @include respond-to(md) {
    margin-bottom: 90px;
  }
}
.md\:mb100 {
  @include respond-to(md) {
    margin-bottom: 100px;
  }
}
.md\:mb110 {
  @include respond-to(md) {
    margin-bottom: 110px;
  }
}
.md\:mb120 {
  @include respond-to(md) {
    margin-bottom: 120px;
  }
}

.lg\:mt0 {
  @include respond-to(lg) {
    margin-top: 0;
  }
}
.lg\:mt10 {
  @include respond-to(lg) {
    margin-top: 10px;
  }
}
.lg\:mt20 {
  @include respond-to(lg) {
    margin-top: 20px;
  }
}
.lg\:mt30 {
  @include respond-to(lg) {
    margin-top: 30px;
  }
}
.lg\:mt40 {
  @include respond-to(lg) {
    margin-top: 40px;
  }
}
.lg\:mt50 {
  @include respond-to(lg) {
    margin-top: 50px;
  }
}
.lg\:mt60 {
  @include respond-to(lg) {
    margin-top: 60px;
  }
}
.lg\:mt70 {
  @include respond-to(lg) {
    margin-top: 70px;
  }
}
.lg\:mt80 {
  @include respond-to(lg) {
    margin-top: 80px;
  }
}
.lg\:mt90 {
  @include respond-to(lg) {
    margin-top: 90px;
  }
}
.lg\:mt100 {
  @include respond-to(lg) {
    margin-top: 100px;
  }
}
.lg\:mt110 {
  @include respond-to(lg) {
    margin-top: 110px;
  }
}
.lg\:mt120 {
  @include respond-to(lg) {
    margin-top: 120px;
  }
}

.lg\:mb10 {
  @include respond-to(lg) {
    margin-bottom: 10px;
  }
}
.lg\:mb20 {
  @include respond-to(lg) {
    margin-bottom: 20px;
  }
}
.lg\:mb30 {
  @include respond-to(lg) {
    margin-bottom: 30px;
  }
}
.lg\:mb40 {
  @include respond-to(lg) {
    margin-bottom: 40px;
  }
}
.lg\:mb50 {
  @include respond-to(lg) {
    margin-bottom: 50px;
  }
}
.lg\:mb60 {
  @include respond-to(lg) {
    margin-bottom: 60px;
  }
}
.lg\:mb70 {
  @include respond-to(lg) {
    margin-bottom: 70px;
  }
}
.lg\:mb80 {
  @include respond-to(lg) {
    margin-bottom: 80px;
  }
}
.lg\:mb90 {
  @include respond-to(lg) {
    margin-bottom: 90px;
  }
}
.lg\:mb100 {
  @include respond-to(lg) {
    margin-bottom: 100px;
  }
}
.lg\:mb110 {
  @include respond-to(lg) {
    margin-bottom: 110px;
  }
}
.lg\:mb120 {
  @include respond-to(lg) {
    margin-bottom: 120px;
  }
}

.list--inline {
  display: flex;
  flex-wrap: wrap;
}

.hidden,
.hide {
  display: none;
}

.video-js {
  .vjs-big-play-button {
    width: 50px;
    height: 52px;
    border: none;
    background: transparent var(--button-play-01) no-repeat center center;
    background-color: transparent !important;
    background-size: contain;
    .vjs-icon-placeholder {
      display: none;
    }
  }
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-red {
  color: $red;
}

.text-blue {
  color: $blue;
}

.text-xlg {
  font-size: 2rem;
  @include respond-to(md) {
    font-size: 2.2rem;
  }
}

.img-rect--v {
  display: block;
  position: relative;
  padding-top: 125%;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.img-square {
  display: block;
  position: relative;
  padding-top: 100%;
  border-radius: 8px;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 8px;
  }
}

.img-product {
  display: block;
  position: relative;
  padding-top: 125%;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
  &.img-product--s {
    padding-top: 125%;
  }
}



.img-platinum {
  display: block;
  position: relative;
  padding-top: 58%;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.md-visible {
  display: none;
  @include respond-to(md) {
    display: block;
  }
}

.md-visible--inline {
  display: none;
  @include respond-to(md) {
    display: inline-block;
  }
}

.md-hidden {
  @include respond-to(md) {
    display: none;
  }
}

.sm-hidden {
  @include respond-to(sm) {
    display: none;
  }
}

.sm-visible {
  display: none;
  @include respond-to(sm) {
    display: block;
  }  
}

.sm-visible--inline {
  display: none;
  @include respond-to(sm) {
    display: inline;
  }  
}

.error {
  margin-top: 4px;
  color: $red;
}

.dl--stack {
  @include text-base();
  dt {
    margin-bottom: 4px;
    line-height: 1.8em;
  }
  dd {
    margin-bottom: 30px;
  }
}

.stacks {
  .stack {
    margin-bottom: 16px;
  }
}

.font--bold {
  font-family: var(--font-b);
}

.uppercase {
  text-transform: uppercase;
}

.bgc--light-gray {
  background-color: $superLightGray;
}

.bdr--top {
  border-top: $lightGray;
}

.bdr--right {
  border-right: $lightGray;
}

.bdr--bottom {
  border-bottom: $lightGray;
}

.bdr--left {
  border-left: $lightGray;
}

.sticky-contents {
  width: 100%;
  position: sticky;
  top: 84px;
  @include respond-to(md) {
    position: static;
  }
  .page-title {
    width: 100%;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-left: -16px;
  @include respond-to(sm) {
    margin-right: -6px;
    margin-left: -6px;
  }   
  &.row--center {
    justify-content: center;
  }   
  &.row--v-center {
    align-items: center;
  }   
  &.row--reverse {
    flex-direction: row-reverse;
  }       
}

.col {
  padding: 0 16px;
  @include respond-to(sm) {
    padding-right: 6px;
    padding-left: 6px;
  }  
}

.col--auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col--right {
  margin-left: auto;
}

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.col-md-1 {
  @include respond-to(md) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
}

.col-md-2 {
  @include respond-to(md) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
}

.col-md-3 {
  @include respond-to(md) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.col-md-4 {
  @include respond-to(md) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

.col-md-5 {
  @include respond-to(md) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
}

.col-md-6 {
  @include respond-to(md) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.col-md-7 {
  @include respond-to(md) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
}

.col-md-8 {
  @include respond-to(md) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
}

.col-md-9 {
  @include respond-to(md) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
}

.col-md-10 {
  @include respond-to(md) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
}

.col-md-11 {
  @include respond-to(md) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
}

.col-md-12 {
  @include respond-to(md) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.col-lg-1 {
  @include respond-to(lg) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
}

.col-lg-2 {
  @include respond-to(lg) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
}

.col-lg-3 {
  @include respond-to(lg) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.col-lg-4 {
  @include respond-to(lg) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

.col-lg-5 {
  @include respond-to(lg) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
}

.col-lg-6 {
  @include respond-to(lg) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.col-lg-7 {
  @include respond-to(lg) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
}

.col-lg-8 {
  @include respond-to(lg) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
}

.col-lg-9 {
  @include respond-to(lg) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
}

.col-lg-10 {
  @include respond-to(lg) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
}

.col-lg-11 {
  @include respond-to(lg) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
}

.col-lg-12 {
  @include respond-to(lg) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.span-xl-12 {
  width: auto;      
}
.span-xl-11 {
  width: calc(100vw / 12 * 11);
}
.span-xl-10 {
  width: calc(100vw / 12 * 10);
}
.span-xl-9 {
  width: calc(100vw / 12 * 9);
}
.span-xl-8 {
  width: calc(100vw / 12 * 8);
}
.span-xl-7 {
  width: calc(100vw / 12 * 7);
}
.span-xl-6 {
  width: calc(100vw / 12 * 6);
}
.span-xl-5 {
  width: calc(100vw / 12 * 5);
}  
.span-xl-4 {
  width: calc(100vw / 12 * 4);
}
.span-xl-3 {
  width: calc(100vw / 12 * 3);
}  
.span-xl-2 {
  width: calc(100vw / 12 * 2);
}

.scroll-container {
  overflow-y: auto;
  &::-webkit-scrollbar{
    width: 3px;
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar-track{
    background-color: transparent;
    border: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $primaryColor;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: $primaryColor;
    }
  }
}

.site-wrapper {
  overflow: hidden;
}

.section-title {
  @include h4();
  font-weight: bold;
}

.container {
  //overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  padding: 40px $containerPD;
  max-width: 1160px;
  @include respond-to(md) {
    padding: 56px $mdContainerPD;
  }
}

.container--full {
  max-width: none;
  padding: 0;
}

.container--fluid {
  max-width: none;
  @include respond-to(md) {
    padding-left: $mdContainerPD;
    padding-right: $mdContainerPD;
  }  
}

.container--md {
  max-width: 800px;
}

.container--sm {
  max-width: 480px;
}

.swiper-wrapper {
  height: auto;  
}

// .swiper-button-next {
//   padding-right: $containerPD;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   width: 60px;
//   height: auto;
//   &::after {
//     content: "";
//     display: block;
//     margin-left: auto;
//     width: 24px;
//     height: 24px;
//     background: url(../img/icon_arrow_right.svg) no-repeat center center;
//     background-size: contain;
//   }
// }

// .swiper-button-prev {
//   justify-content: flex-start;
//   padding-left: $containerPD;
//   left: 0;
//   top: 0;
//   bottom: 0;
//   width: 60px;
//   height: auto;
//   &::after {
//     content: "";
//     display: block;
//     margin: 0;
//     width: 24px;
//     height: 24px;
//     background: url(../img/icon_arrow_left.svg) no-repeat center center;
//     background-size: contain;
//   }
// }

.swiper-horizontal { 
  .swiper-pagination {
    bottom: 32px;
    z-index: 1000;
    text-align: right;
    @include respond-to(md) {
      padding-right: 32px;
      bottom: 32px;
    }
  }
}

.swiper-pagination-bullet {
  width: 32px;
  height: 2px;
  background-color: #fff;
}

.site-header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100%;
  background-color: #fff;
  &.nav-open {
    .logo {
      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
  .container {
    padding-top: 0;
    padding-bottom: 0;
    min-height: 64px;
    // background-color: #fff;
    // &::before {
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   z-index: 2;
    //   width: 100%;
    //   min-height: 88px;
    //   background-color: #fff;
    //   box-shadow: 0px 6px 10px 1px rgba(0,0,0,0.05);
    //   content: "";
    // }
  }
  .logo {
    display: inline-block;
    position: absolute;
    left: $containerPD;
    top: 50%;
    z-index: 10000;
    transform: translateY(-50%);
    width: 142px;
    @include respond-to(md){
      left: $mdContainerPD;
      width: 180px;
    }
    svg {
      max-width: 100%;
      height: auto;
      path {
        transition: fill 0.3s ease;
      }
    }
  }
  .hamburger-button {
    @include respond-to(md){
      display: none;
    }
  }
}

.site-nav {
  visibility: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
  padding-top: 64px;
  font-size: 1.6rem;
  width: 100%;
  min-height: 100vh;
  background-color: $primaryColor;
  transform: translateX(100%);
  transition: transform 0.3s ease, visibility 0.3s ease;
  @include respond-to(md){
    visibility: visible;
    transform: none;
    position: static;
    padding-top: 0;
    min-height: 0;
    font-size: 1.4rem;
    background-color: #fff;
    transition: none;
  }
  &.open {
    visibility: visible;
    transform: translateX(0);
  }
  &__menu {
    // position: absolute;
    // left: 0;
    //top: 64px;
    // width: 100%;
    // background-color: $primaryColor;
    border-top: 1px solid rgba(255,255,255,0.2);
    @include respond-to(md){
      display: flex;
      justify-content: flex-end;
      background-color: #fff;
      // box-shadow: 0px 6px 10px 1px rgba(0,0,0,0.05);
      margin-right: -16px;
    }
    li {
      position: relative;
      border-bottom: 1px solid rgba(255,255,255,0.2);
      // @include respond-to(md){
      //   padding: 0 16px;
      // }
      @include respond-to(md){
        margin: 0 16px;
      }
      &:hover {
        .site-nav__child-menu-container {
          display: block;
        }
      } 
      a {
        display: block;
        position: relative;
        line-height: 64px;
        padding: 0 $containerPD;
        @include respond-to(md){
          padding: 0;
        }
        &::before {
          @include respond-to(md){
            display: inline-block;
            margin-right: 8px;
            width: 6px;
            height: 6px;
            background-color: $primaryColor;
            transform: rotate(45deg) translateY(-2px);
            content: '';
          }
        }
        &:link, &:visited {
          color: #fff;
          @include respond-to(md){
            color: $baseColor;
          }
        }
        // &:hover, &:active {
        //   //media query for hover
        //   @media (hover: hover) {
        //     &::after {
        //       width: 100%;
        //     }
        //   }
        // }
      }
      span {
        display: inline-block;
        position: relative;
        &::after {
          display: inline-block;
          position: absolute;
          right: 0;
          bottom: 12px;
          width: 0;
          height: 2px;
          background-color: $primaryColor;
          content: "";
          transition: width 500ms cubic-bezier(.215, .61, .355, 1);
        }
        &:not(:hover){
          &::after {
            @media (hover: hover) {
              left: 0;
              width: 0;
            }
          }
        }
        &:hover {
          &::after {
            @media (hover: hover) {
              right: 0;
              width: 100%;
            }
          }
        }
      }
    }
  }
  &__sub-menu {
    padding: 24px $containerPD;
    font-size: 1.3rem;
    text-align: center;
    @include respond-to(md){
      display: none;
    }
    a {
      &:link, &:visited {
        color: #fff;
      }
    }
  }
}

.search-form {
  margin: 0 0 40px 0;
  @include respond-to(md){
    display: block;
    padding: 40px 24px;
    border-radius: 8px;
    background-color: #fff;
  }
  &__group {
    display: flex;
    align-items: center;
    @include respond-to(md){
      display: none;
    }
  }
  &__keyword-search {
    width: calc(100% - 32px);
    input[type="text"]{
      padding-left: 40px;
      max-width: none;
      background-image: url(../img/icon_search.svg);
      background-position: left 8px center;
      background-repeat: no-repeat;
    }
  }
  &__filter-search {
    margin-left: auto;
  }
  &__menu {
    display: none;
    overflow-y: auto;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 1000;
    padding: 60px $containerPD 60px $containerPD;
    width: 100%;
    height: calc(100vh - 80px);
    border-radius: 16px 16px 0 0;
    background-color: #fff;
    @include respond-to(md){
      display: block;
      position: static;
      padding: 0;
      height: auto;
      border-radius: 0;
    }
    &.open {
      display: block;
    }
  }
  &__menu-container {
    padding-bottom: 100px;
    @include respond-to(md){
      padding-bottom: 0;
    }
  }
  &__close-button {
    position: absolute;
    right: $containerPD;
    top: 24px;
    @include respond-to(md){
      display: none;
    }
  }
  dt {
    margin-bottom: 16px;
  }
  dd {
    margin-bottom: 20px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      margin-right: 8px;
      margin-bottom: 8px;
      &.category__item {
        margin-right: 0;
        margin-bottom: 0;
        width: 100%;
      }
    }
  }
  .category__child-items {
    display: none;
    margin-top: 16px;
    padding-bottom: 8px;
    border-bottom: 1px solid $lightGray;
    &.visible {
      display: flex;
    }
  }
  .category__item-inner {
    position: relative;
    border-bottom: 1px solid $lightGray;
    label {
      display: block;
      padding: 8px 0;
      width: 100%;
      background-color: transparent;
      &.checked {
        background-color: transparent;
       }
      input[type="checkbox"] {
        display: inline-block;
        position: relative;
        top: 0;
        margin-right: 6px;
      }
    }
    button {
      display: none;
    }
  }
  label {
    @include text-sm();
    display: inline-block;
    padding: 6px 16px 8px 16px;
    color: $darkGray;
    border-radius: 100px;
    background-color: $lightGray;
    &.checked {
     background-color: $accentColor; 
    }
    input[type="checkbox"] {
      display: none;
    }
  }
}

.site-trunk {
  padding-top: 0;
}

.contents-container {
  display: flex;
  .site-trunk {
    width: calc(100% - 240px);
  }
  .sidebar {
    width: 240px;
    padding: 40px 24px;
    background-color: #fff;
    // @include respond-to(md){
    //   display: none;
    // }
  }
}

.hamburger-button {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2000;
  margin: 0 auto;
	width: calc(24px + #{$containerPD} + #{$containerPD});
	height: 64px; 
	cursor: pointer;
	.bar {
		display: block;
		position: absolute;
		top: 50%;
		right: $containerPD;
		width: 24px;
    height: 2px;
    background-color: $primaryColor;
    transition: background-color 200ms ease;
    // @include tablet {
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   width: 25px;
    //   height: 2px;
    // }    
		&::before, &::after {
			display: block;
			content: "";
			position: absolute;
			top: 0;
			left: 0;
      width: 24px;
      height: 2px;
			background: $primaryColor;
      border-radius: 8px;
      transition: transform 400ms ease;
      @include tablet {
        width: 25px;
        height: 2px;
      }
		}
		&::before {
			margin-top: -8px;
		}
		&::after {
			margin-top: 8px;
		}
	}
	&.open {
		.bar {
			background-color: transparent;
			&::before, &::after {
				margin-top: 0;
        //width: 22px;   
        background-color: #fff;    
			}
			&::before {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        @include tablet {
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        }  
			}
			&::after {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        @include tablet {
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        } 
			}
		}
	}
}

.overlay-section {
  margin-top: -24px;
  border-radius: 24px 24px 0 0;
  background-color: $superLightGray;
}

.box {
  position: relative;
  padding: 24px 20px 40px 20px;
  border-radius: 16px;
  background-color: #fff;
  &__title {
    font-weight: bold;
  }
  &__summary {
    // overflow: hidden;
    display: -webkit-box;             /* Flexboxを使用してボックスを作成 */
    -webkit-box-orient: vertical;     /* 縦方向にボックスを設定 */
    -webkit-line-clamp: 3;            /* 表示する行数を指定 */
    overflow: hidden;                 /* 見切れた部分を隠す */
    position: relative;
    padding: 0 0 24px 0;
    //height: 10em;
    &::after {
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2em;
      background: rgb(255,255,255);
      background: linear-gradient(0deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%);
      content: "";
    }
  }
  &--accordiong {
    //padding: 0;
  }
  &__toggle-button {
    @include text-base();
    display: block;
    margin-left: -24px;
    margin-right: -24px;
    padding: 24px 24px;
    width: 100%;
    border-top: 1px solid $lightGray;
    color: $baseColor;
    font-weight: bold;
    text-align: left;
    background: url(../img/icon_arrow_down.svg) no-repeat right 24px center;
  }
  &__more-button {
    @include text-base();
    position: absolute;
    left: 0;
    bottom: 16px;
    width: 100%;
    font-weight: bold;
    &::after {
      display: inline-block;
      margin-left: 8px;
      width: 15px;
      height: 9px;
      background: url(../img/icon_arrow_down.svg) no-repeat center center;
      content: "";
    }
  }
  &__content {
    line-height: 1.8em;
  }
}


.section__title {
  margin-bottom: 48px;
  font-size: 1.2rem;
  font-weight: 600;
}

.section__subtitle {
  font-size: 2.8rem;
  font-weight: 500;
  @include respond-to(md) {
    font-size: 3.2rem;
  }
}

.button {
  display: flex;
  align-items: center;
  position: relative;
  text-decoration: underline;
  text-underline-offset: 8px;
  &:link, &:visited {
    text-decoration: underline;
  }
  &::before {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-right: 16px;
    // position: absolute;
    // left: 0;
    // top: 0.7em;
    border-radius: 100px;
    background: #F79D25 url(../img/icon_arrow_01_white.svg) no-repeat center center;
    content: "";
  }
}

.view-list-button {
  position: absolute;
  right: $containerPD;
  top: 60px;
}

.site-footer {
  // margin-top: 40px;
  @include respond-to(md) {
  }
  .container {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  &__menu {
    font-size: 1.3rem;
  }
  .row {
    margin-right: -16px;
    margin-left: -16px;
    .col {
      padding: 0 16px;
    }
  }
  .copy {
    font-size: 1.3rem;
  }
}

.prlx {
  opacity: 0;
  transition: opacity ease 0.8s;
  &.executed {
    opacity: 1;
  }
}